import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN
const SENTRY_SAMPLE_RATE = process.env.SENTRY_SAMPLE_RATE
const IS_SENTRY_ENABLED = process.env.IS_SENTRY_ENABLED

if( IS_SENTRY_ENABLED ) {
    Sentry.init({
        // Authentication
        dsn: SENTRY_DSN,

        // Settings
        integrations: [new BrowserTracing()],
        tracesSampleRate: SENTRY_SAMPLE_RATE,
    })
}
