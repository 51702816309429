/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import './sentry';

// any CSS you import will output into a single css file (app.css in this case)
import '../scss/app.scss';

import './_autoloader';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import Alpine from 'alpinejs';
import './utils'
import './debouncedFetch';
import './dialogs'
import './dirtyForm'
import './tooltips'
import axios from 'axios';
import Tagify from '@yaireo/tagify';
import { v4 as uuidv4 } from 'uuid';

if (document.querySelectorAll('.additional-insured-person').length >= 1) {
    import('../../templates/Form/themes/types/column/additional_insured_person_column');
}

if (document.querySelectorAll('body.catgory-code-G016').length >= 1) {
    import('./G016');
}

// The preload class prevent css animations on page load
// remove the body preload class
setTimeout(_ => {
    document.body.classList.remove('preload')
}, 500)

// Register some libraries globally
window.axios = axios;
window.Tagify = Tagify;
window.Alpine = Alpine
window.uuidv4 = uuidv4

if (!window.preventAlpineStart) {
    window.Alpine.start()
}
